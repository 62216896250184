.show-only-on-print {
  display: none !important;
}

@media print {
  body {
    height: auto !important;
    min-height: auto !important;
  }

  cx-page-layout.alerts {
    display: none !important;
  }

  py-unsupported-message,
  py-password-message,
  cx-global-message,
  div.cart-message-wrapper {
    display: none !important;
  }

  main.contain-layout {
    contain: layout;
  }

  py-search {
    display: none !important;
  }

  py-back-to-top {
    display: none !important;
  }

  py-footer-navigation {
    display: none !important;
  }

  py-mail-form {
    display: none !important;
  }

  nz-pagination,
  py-show-per-page {
    display: none !important;
  }

  cx-page-layout.MyPageTemplate {
    .Section1A {
      display: none !important;
    }
  }

  cx-page-slot.FooterLogo {
    display: none !important;
  }

  header.storefront-header {
    padding: 0 !important;
    height: auto !important;
    margin-bottom: 10px;
  }

  .grecaptcha-badge {
    display: none !important;
  }

  .DesktopNavigation {
    display: none !important;
  }

  .hide-on-print {
    display: none !important;
  }

  .show-only-on-print {
    display: block !important;
  }

  .display-block-on-print {
    display: block !important;
  }

  .break-before-on-print {
    page-break-before: always !important;
  }
}
